import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

const Box = styled.div`
  padding: 1.45rem 1.45rem 1.45rem 1.45rem;
  border: 1px solid var(--color-primary-border);
  width: 50%;
  min-height: 20vh;
  max-height: 20vh;
  text-align: center;
  margin: auto;
`;
const Toolbar = styled.div`
  margin-bottom: 5vh;
  display: flex;
  flex-direction: row;
`;
const Text = styled.p`
  padding: 10px 10px 10px 10px;
  line-height: 150%;
`;
const Link = styled.a`
  text-decoration: none;
  color: var(--color);
`;

export default () => (
  <Layout>
    <Toolbar>
      <Box style={{}}>
        <h3>Kredite</h3>
        <Text>Privatkredite und Unternehmenskredite</Text>
      </Box>
      <Box>
        <h3>Versicherung und Vorsorge</h3>
        <Text>Für Firmen und Privatpersonen</Text>
      </Box>
      <Box>
        <h3>Beteiligungen an Firmen</h3>
        <Text>Haben Sie Fragen zu Krediten oder Beteiligungen? Wir beraten Sie gerne in allen Bereichen.</Text>
      </Box>
    </Toolbar>

    <Toolbar>
      <Box>
        <h3>Finanzierungen</h3>
        <Text>Gerne beraten wir Sie in diesen Themen. Melden Sie sich bei uns! </Text>
      </Box>
      <Box>
        <h3>Virtual Office</h3>
        <Text>Baar Ihr neues Firmendomizil</Text>
      </Box>
      <Box>
        <h3>Firmengründung</h3>
        <Text>Wir erledigen alles um die Gründung Ihrer Firma</Text>
      </Box>
      <Box>
        <h3>Unternehmungsberatung</h3>
        <Text>Gerne beraten wir Sie in diesen Themen. Melden Sie sich bei uns!</Text>
      </Box>
    </Toolbar>

    <Toolbar>
      <Box>
        <h3>Steuerberatung</h3>
        <Text>Steuererklärung und Steuereinsparung</Text>
      </Box>
      <Box>
        <h3>Buchhaltung</h3>
        <Text>Brauchen Sie Hilfe bei der Verwaltung Ihrer Buchhaltung? Gerne helfen wir Ihnen dort weiter.</Text>
      </Box>
      <Box>
        <h3>Schreibservice</h3>
        <Text>Wir bietet unkomplizierte Unterstützung bei administrativen Anliegen.</Text>
      </Box>
      <Box>
        <h3>Kauf- & Verkauf-Liquidation von Firmen</h3>
        <Text>Gerne helfen wir Ihnen dort weiter. Melden Sie sich bei uns!</Text>
      </Box>
    </Toolbar>
  </Layout >
);
